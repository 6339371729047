import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=09963726&scoped=true&"
import script from "./Chat.vue?vue&type=script&lang=js&"
export * from "./Chat.vue?vue&type=script&lang=js&"
import style0 from "./Chat.vue?vue&type=style&index=0&id=09963726&prod&lang=css&"
import style1 from "./Chat.vue?vue&type=style&index=1&id=09963726&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09963726",
  null
  
)

/* custom blocks */
import block0 from "./Chat.vue?vue&type=custom&index=0&blockType=svg&height=2.5em&viewBox=0%200%20500%20500&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"
if (typeof block0 === 'function') block0(component)

export default component.exports