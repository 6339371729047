d
The basic shell for the whole app, displaying the left spacer bar (with navigation buttons and clock),
and then the router-view for main content 

This component handles window resizing and resulting style shifting fof the whole site

<template>
<div>
  <Chat v-if="/*pageType === 'rail' && */ ($isMobile || $isTabletForm) && query.layout !== 's'" :mobile="true" :tablet="$isTabletForm && !$isMobile" :isRotated="$isRotatedMobile" :largeTablet="$isLargeTablet" />

  <div id="app" 
    :class="'main' 
          + ($isMobile ? ' mobile' : '') 
          // + (($isMobile && pageType && pageType !== 'rail') ? ' scroll-container-mobile' : '') 
          + (($isTabletForm /*|| query.hidemodeswitch || $isSignMode*/) ? ' smallscreen' : '') 
          + (($isSignMode || query.hidemodeswitch) ? ' hidemodeswitch' : '')
          + ($isSignMode ? ' sign' : '')
          + ($isRotatedMobile ? ' full-rotate' : '')
          + ((query.dark !== false) ? ' dark' : '')
          + (($isTabletForm) ? '' : ' landscape-container')
          + (($isVeryLargeScreen) ? ' very-large-screen' : '')
          + (query.inStation ? ' in-station' : '')
          + (($route.fullPath.indexOf('trips') > 0) ? ' trip-page' : '')
          "
  >
  <Chat v-if="/*pageType === 'rail' &&*/ !$isMobile && !$isTabletForm && query.layout !== 's'" :largeTablet="$isLargeTablet"/>

    <div :class="'left-spacer ' + pageType" v-if="pageType !== '' && !$isSignMode">
      <!-- <NavigationButtons :showNav="true" :pageType="pageType" :query="query" /> -->
    </div>

    <router-view :key="$route.fullPath"/>

    <div :class="'mobile-nav' + (showNav ? ' slide-in' : ' slide-out')" v-if="$isTabletForm && pageType !== '' && $data.query.footer === false">
      <NavigationButtons :showNav="showNav" @onComplete="toggleNav" :pageType="pageType" :query="query" />
      <button @click="toggleNav" class="mobile-nav-button">X</button>
    </div>
    <button 
      v-if="$isTabletForm && !(query.hidemodeswitch || $isSignMode) && pageType !== '' && $data.query.footer === false" 
      :class="'mobile-nav-button' + (showNav ? ' slide-out' : ' slide-in')"
      @click="toggleNav"
    >
      MENU
    </button>

    <div v-if="false && $isDisconnected"
      style="
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 100;
        height: 20vh;
        width: 20vh;
        background-color: red;
        color: white;
      "
    >
        Network Disconnection - too many consecutive timeouts
    </div>
  </div>

  <div ref="tripFooter" :class="'footer' + ($isRotatedMobile ? ' rotated' : '')" v-if="$isTabletForm && pageType !== '' && $data.query.footer !== false">
    <NavigationButtons :showNav="true" :pageType="pageType" :query="query" />
  </div>
</div>
</template>

<script>
  import NavigationButtons from "@/components/NavigationButtons";
  // import ClockDisplay from "@/components/ClockDisplay";
  import { setQueryParameters, onlyNonNullValues, determineDefaultFontSize } from "@/Utilities";

  import { debounce } from "@/Utilities";

  import { setCurrentStore } from "@/requests/BaseRequestValues";

  import Chat from "@/components/Chat";

  const defaultDebounceWait = 150;

  console.log("NAVIGATION BUTTONS", NavigationButtons);

  let listenerFunc = null;

  let prev = null;

  export default {
    name: "App",
    data: () => ({
      pageType: "bus",
      appliedFontSize: null,
      showNav: false,
      query: {
        layout: "",
        fontsize: "",
        scrolling: "",
        route: "",
        direction: "",
        stop: "",
        late: null,
        hidemodeswitch: false,
        footer: null, 
        dark: null,
        paramid: null,
        inStation: null
      },
      isTripsPage: false
    }),
    components: {
        NavigationButtons, 
        Chat
      // ClockDisplay
    },
    watch:{
      $route (to, from){
        // when we switch type, be sure to change the state and display color
        this.pageType = this.$route.path.split("/")[1];
        this.setPrimaryColor();

        this.showNav = false;

        const toPath = to.path.split("/")[1];
        const fromPath = from.path.split("/")[1];

        console.log("WATCH ROUTE", to, from, toPath, fromPath);

        if(toPath !== fromPath){
          console.log("ROUTES NOT EQUAL QUERY", "_" + toPath + "_", "_" + fromPath + "_");
          this.refreshQueryParams();
          if(toPath === ""){
            this.isLanding = true;
          }
          else {
            // record the new path
            localStorage.setItem("__SELECTED_DEFAULT_PAGE_KEY__", toPath);
          }
        }

        if(to.path !== from.path){
          // be sure to reset the disconection count if we switch pages
          this.$store.commit("setIsDisconnected", false);

          setTimeout(() => {
            window.triggerScrollCheck();
          }, 500);
        }
      },
      $isMobile (to, from){
        console.log("ISMOBILE SIZE CHANGING", to, from);
      },
      $isTabletForm (to, from){
        console.log("isTabletForm SIZE CHANGING", to, from);
      },
      $isLargeTablet (to, from){
        console.log("Large Tablet SIZE CHANGING", to, from);
      },
      $isVeryLargeScreen (to, from){
        console.log("ISVERYLARGESCREEN SIZE CHANGING", to, from);
      }
    },
    mounted() {
      // get the page type
      this.pageType = this.$route.path.split("/")[1];

      setCurrentStore(this);

      this.setPrimaryColor();

      // this.refreshQueryParams();

      // set the size and start the resize event listener

      const setFunc = () => {
        this.refreshQueryParams();
      }

      console.log("INITIAL QUERY CHECK", this.query, this.$mq);
      setFunc();

      if(listenerFunc){
        window.removeEventListener("resize", listenerFunc);
      }

      listenerFunc = () => {
        // be sure not to keep calling this, instead waiting for the resizing to stop
        debounce(() => {
          console.log("SIZE CALL TO STORE MADE");
          setFunc();
        }, defaultDebounceWait)();
      }

      window.addEventListener(
        'resize',
        listenerFunc
      )

      setTimeout(() => {
        console.log("RUN CHECK FOR SCROLLBARS");
        window.triggerScrollCheck();
      }, 0);

      console.log("APP ROUTE", this.$route);
      this.isTripsPage = this.$route.name === "Trips";

            // setTimeout(() => {
            //     const main = this.$refs.tripFooter;
            //     console.log("TRIP MAIN ELEMENT", main);

            //     const val = document.createElement("div");
            //     val.classList.add("size-marker");

            //     const sizeDef = main.style.height;
            //     const sizeVal = main.offsetHeight;
            //     const sizeRect = main.getBoundingClientRect().height;

            //     const full = window.innerHeight;

            //     val.innerText = `H: ${sizeDef}:: ${sizeVal}:: ${sizeRect} // ${full}`;

            //     main.appendChild(val);
            // }, 300);
    },
    updated(){
      console.log("UPDATE TRIGGERED?", this.$route);
      // only run this if the path is different?
      if(prev !== this.$route.fullPath){
        this.refreshQueryParams();
        prev = this.$route.fullPath;
      }
    },
    destroyed() {
      console.log("INITIAL APP DESTROY RUN?");
      window.removeEventListener("resize", listenerFunc);
    },
    methods: {
      setPrimaryColor() {
        if(this.pageType === "bus"){
          document.documentElement.style.setProperty('--primary-color', 'var(--bus-primary)');
          document.documentElement.style.setProperty('--footer-background', 'var(--bus-footer)');
        }

        if(this.pageType === "lightrail"){
          document.documentElement.style.setProperty('--primary-color', 'var(--lightrail-primary)');
          document.documentElement.style.setProperty('--footer-background', 'var(--lightrail-footer)');
        }

        if(this.pageType === "rail"){
          document.documentElement.style.setProperty('--primary-color', 'var(--rail-primary)');
          document.documentElement.style.setProperty('--footer-background', 'var(--rail-footer)');
        }
      },
      toggleNav() {
        this.showNav = !this.showNav;
      },
      refreshQueryParams(){

        console.log("REFRESH QUERY PARAMS", this.query, this.$mq);

        setQueryParameters(this);

        console.log("REFRESH AFTER QUERY SET", this.query, this.$mq, this.$isMobile, this.$isTabletForm);

        this.query = onlyNonNullValues(this.query);

        this.setSize();
      },
      setSize(){
        let size = this.$mq;
        const screen = window.innerWidth;
        const height = window.innerHeight;

        if(this.$mq !== "mobile" && this.$mq !== "smallscreen" && this.$mq !== "large"){
          if(screen <= 600){
            size = "mobile";
            alert("WE ARE MOBILE " + screen);
          }
          else if(screen <= 1050){
            size = "smallscreen"
          }
          else{
            size = "large";
          }
        }

        console.log("SET SIZE", size, screen);

        console.log("RUN THE RESIZE QUERY CHECK REFRESH", this.query.layout, this.query, "AA_" + this.$mq + "_AA", size);
        this.$store.commit('setWindowSize');
        this.$store.commit("setIsMobile", (size === 'mobile' || this.query.layout === "m") && this.query.layout !== 'l' && this.query.layout !== 's');
        this.$store.commit("setIsSmallScreen", (size === 'smallscreen' || size === 'mobile' || this.query.layout === 'm') && this.query.layout !== 'l' && this.query.layout !== 's');
        // this is the only circumstances, right?
        console.log("SIGNAGE MODE LAYOUT?", this.query);
        this.$store.commit("setSignMode", this.query.layout === 's');
        console.log("QUERY is mobile", this.$mq, size, (size === 'mobile' || this.query.layout === "m"), this.query.layout !== 'l' && this.query.layout !== 's');
        console.log("AFTER QUERY CHECK", this.$isMobile, this.$isSmallScreen);

        if(size === "smallscreen" && screen >= 780 && screen <= 920) {
          console.log("SET SIZE BORDERLINE TABLET", size, screen);
          this.$store.commit("setIsMidTablet", true);
        }
        else{
          this.$store.commit("setIsMidTablet", false);
        }

        if(size === "smallscreen" && screen >= 921 && screen <= 1049) {
          console.log("SET SIZE BORDERLINE TABLET", size, screen);
          this.$store.commit("setIsBorderlineTablet", true);
        }
        else{
          this.$store.commit("setIsBorderlineTablet", false);
        }

        if(size === "large") {
          console.log("SET SIZE BORDERLINE LAPTOP", size, screen);
          // running the check on the other side
          this.$store.commit("setIsBorderlineLaptop", true);
        }
        else{
          this.$store.commit("setIsBorderlineLaptop", false);
        }

        if(height > 1900) {
          this.$store.commit ("setIsVeryLargeScreen", true);
        }
        
        this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq, this.$isTabletForm, "APP");

        if(this.appliedFontSize){
          document.documentElement.style.setProperty('--font-size', `${this.appliedFontSize}`);
        }
      }
    }
  }
</script>

Styles that apply only to this component

<style>
  html {
    font-size: 2vmin;
  }

/* potential solution for apple mobile "overscroll" */
  body {
    overscroll-behavior-y: none;
  }

  .fade-scroll .extra {
    animation: fadeOut 1s ease-in-out forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 0;
    }
  }

  .has-scroll {
    position: relative;
  }

  .mobile .has-scroll .extra {
    display: initial;
    position: absolute;
    bottom: calc(5 * var(--mvh));
    left: 50vw;
    transform: translate(-50%);
    opacity: 0.6;
    /* animation: none; */
  }

  .mobile .has-scroll .extra::before {
    content: "";
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%);
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-top: 10px solid black;
  }

  .message-hidden {
    display: none;
  }

  /* .scroll-container-mobile::before {
    content: "";
    position: absolute;
    bottom: 5vh;
    right: 15vw;
    height: 5rem;
    width: 2rem;
    border-radius: 1rem;
    background: white;
    opacity: 0.8;
    z-index: 10000;
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 1rem 1rem white;
    animation: delayedFade 8s linear forwards;
  }

  .scroll-container-mobile::after {
    content: "       scroll";
    white-space: pre;
    position: absolute;
    color: var(--primary-color);
    font-size: 1rem;
    bottom: calc(5vh + 0.5rem);
    right: calc(15vw + 0.5rem);
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
    background: var(--primary-color);
    opacity: 0.8;
    z-index: 10001;
    animation: scroll 8s linear forwards;
  } */

  @keyframes delayedFade {
    0% {
      opacity: 0.8;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  .v-expansion-panel-header {
    min-height: initial !important;
    padding: 8px 14px !important;
  }

  .landscape-container {
    overflow: hidden;
  }

  div .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }

</style>

<style scoped>

/* const defaultMobileFontSize = "calc(2vmax + 0.5vmin)";
const defaultLargeFontSize = "2vmin"; */

  .main:not(.sign) {
    display: grid;
    grid-template-columns: 1fr 1000fr;
    position: relative;
  }

  .left-spacer {
    background-color: var(--primary-color);
    position: relative;
    min-height: calc(100 * var(--mvh));
  }

  .mobile-nav {
    /* background-color: var(--primary-color); */
    border: 2px solid var(--primary-color);
    background: white;
    border-radius: 10px;
  }

  .left-spacer.bus::after {
    content: "";
    background: url("./assets/nj-transit-bus-cropped.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -500px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.1;
    pointer-events: none;
  }
  
  .left-spacer.lightrail::after {
    content: "";
    background: url("./assets/nj-transit-light-rail-cropped.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -450px;
    background-position-y: 50px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.1;
    pointer-events: none;
  }

  .left-spacer.rail::after {
    content: "";
    background: url("./assets/njt_rail.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -350px;
    background-position-y: 50px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.1;
    pointer-events: none;
  }

  .main > * + div::before {
    content: "";
    /* background: url("./assets/njt_logo.svg"); */
    background: url("./assets/hug-icon-s.svg");
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 35vh;
    height: 35vh;
    opacity: 0.02;
    top: -1vh;
    left: 4vw; */
    width: calc(45 * var(--mvh));
    height: calc(45 * var(--mvh));
    opacity: 0.015;
    top: calc(4 * var(--mvh));
    left: 1vw;
    /* bottom: 0;
    right: 0; */
    position: fixed;
    z-index: -1;
    pointer-events: none;

    transform: scale(0.85);
  }

  .main > * + div::after {
    content: "";
    /* background: url("./assets/njt_logo.svg"); */
    background: url("./assets/hug-icon-s.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100 * var(--mvh));
    height: calc(100 * var(--mvh));
    opacity: 0.015;
    top: calc(20 * var(--mvh));
    left: 45vw;
    /* bottom: 0;
    right: 0; */
    position: fixed;
    z-index: -2;
    pointer-events: none;


    transform: scale(0.75);
  }


  .main.mobile > * + div::after {
    top: calc(40 * var(--mvh));
    left: 25vw;
  }

  .main.smallscreen:not(.hidemodeswitch) {
    display: flex;
    height: min(calc(90 * var(--mvh)), 90vh);
  }
  
  .main.smallscreen.trip-page{    
    top: calc(10 * var(--mvh));
  }

  .main.full-rotate.trip-page {
    top: 0;
  }

  .main.mobile {
    display: flex;
    height: min(calc(93 * var(--mvh)), 93vh) !important;
    top: initial !important;
  }

  .main.hidemodeswitch {
    grid-template-columns: calc(2 * var(--mvh)) calc(100vw - 2 * var(--mvh));
  }

  .main.mobile:not(.hidemodeswitch) > * + div,
  .main.smallscreen:not(.hidemodeswitch) > * + div {
    /* width: 97vw; */
    width: 100vw;
    overflow-x: hidden;
  }

  .mobile .left-spacer {
    /* width: 2vw; */
  }

  .smallscreen:not(.mobile) .left-spacer {
    /* width: 2vh; */
  }

  /* @media only screen and (max-width: 600px) {
    .main > * + div {
      width: 97vw;
    }

    .main {
      display: flex;
    }

    .left-spacer {
      width: 3vw;
    }
  } */
</style>

Styles which will apply to the whole application

<style>

  :root {
    --primary-color: var(--bus-primary);
    --font-size: calc(3 * var(--mvh));
    --bus-primary: #07458d; 
    --bus-footer: #91bef2;
    /* 1867c0 */
    --lightrail-primary: #47156f; /* #4e1978; */
    --lightrail-footer: #c09edb;
    /* 6a3993 */
    --rail-primary: #111111;
    --rail-footer: #7b7a7a;    
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: var(--font-size);
  }

        .size-marker {
            position: absolute;
            top: 10%;
            left: 10%;
            background-color: black;
            color: white;
            font-size: 20px !important;
            display: none;
        }

  html {
    /* oh yeah, do I hate having to do this... */
    overflow-y: auto !important;
    font-size: var(--font-size) !important;
  }

  body {
    font-size: 1rem;
    font-family: Roboto;
  }

  .full-rotate {
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .relative {
    position: relative;
  }

  .overflow {
      overflow-y: auto;
  }

  .no-overflow {
      overflow-y: hidden;
  }

  .full-nav {
      height: calc(10 * var(--mvh));
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* font-family: 'Kanit', sans-serif; */
      color: var(--primary-color);
  }

  .no-nav {
      display: none;
  }

  .nav-limited-content {
      height: min(calc(90 * var(--mvh)), 90vh);
  }

  .full-content {
      height: calc(100 * var(--mvh));
  }

  .sub-header {
    height: calc(10 * var(--mvh));
  }

  .nav-limited-inner-content {
    height: min(calc(80 * var(--mvh)), 80vh);
  }

  .full-inner-content {
    height: min(calc(90 * var(--mvh)), 90vh);
  }

  .one-line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  select {
    font-size: 0.8rem;
    padding: 6px;
    margin: 4px 0;
    width: 100%;
  }

  input {
    /* font-size: 0.8rem; */
    padding: 6px;
    margin: 4px 0;
  }

  .banner {
    /*font-family: 'Kanit', sans-serif;*/
    color: var(--primary-color);
    text-align: center;
    letter-spacing: 0.1vw;
    text-transform: capitalize;
    font-size: 2.3rem;
  }

  .landscape .banner {
    margin-top: calc(10 * var(--mvh));
  }

  .landscape.lightrail .banner {
    margin-top: calc(20 * var(--mvh));
  }

  .mobile .banner {
    font-size: 1.6rem !important;
  }

  a {
      font-weight: bold;
      color: blue;
      cursor: pointer;
      margin: 0 0.3rem;
  }

  .time {
    font-weight: bold;
    /* font-size: 1.8rem; */
    font-size: 1.5rem;
  }

  .portrait.data .time {
    font-size: initial;
    margin-right: calc(3 * var(--mvh));
  }

  /* .data {
    background-color: #fff;
  }

  .data:nth-of-type(2n + 1) {
    background-color: #eef;
  } */

  .data {
    /* background-color: #bad8fb; */
    background-color: #c5def9;
    transition: 0.2s;
  }

  .data:hover {
    /* background-color: #ddd; */
  }

  .slide-in {
    position: fixed;
    right: calc(1 * var(--mvh));
    bottom: calc(1 * var(--mvh));
    opacity: 1;
    transition: 0.5s;
    z-index: 10;
  }

  .mobile-nav.slide-in {
    box-shadow: 0 0 0 1000px rgba(0,0,0,0.3);
  }

  .mobile .slide-in,
  .mobile .slide-out {
    max-width: 60vw !important;
  }

  .smallscreen:not(.mobile) .slide-in,
  .smallscreen:not(.mobile) .slide-out {
    max-width: 30vw !important;
  }

  .slide-out {
    position: fixed;
    right: -100%;
    bottom: calc(1 * var(--mvh));
    opacity: 0;
    /* transition: 0.5s; */
    height: 0;
    z-index: 10;
  }

  .mobile-nav-button {
    opacity: 0.7;
    background: white;
    border: 1px solid #999;
    border-radius: 0.5vw;
  }

  .mobile .mobile-nav-button {
    padding: 2vw;
  }

  .slide-in .mobile-nav-button {
    background: none;
  }

  .smallscreen:not(.mobile) .mobile-nav-button {
    padding: calc(2 * var(--mvh)) calc(4 * var(--mvh));
  }

  .mobile-nav .mobile-nav-button {
    display: block;
    margin: 0 0 0 auto;
    padding: 2vw 4vw;
    border: none;
  }

/*
  button {
      background-color: #555;
      color: white;
      padding: 6px;
      border: 1px solid black;
      border-radius: 5px;
  }


  button:hover {
    background-color: #5b76d8;
  }

  button:disabled {
    background-color: #ddd;
    color: #bbb;
    border-color: #ddd;
  }
*/

  .mobile * {
    font-size: 0.88rem;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    /* height: 8vh; */
    height: calc(7 * var(--mvh));
    width: 100%;
    background-color: white;
    border-top: 2px solid var(--primary-color);

    z-index: 5;

    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .footer.rotated {
    height: calc(10 * var(--mvh));
  }

  .footer .v-btn {
    background: white !important;
    border-radius: 10px !important;
    padding-left: 6vw !important;
    padding-right: 6vw !important;
  }

  .footer.rotated .v-btn {
    transform: scale(0.7);
  }

  .alert-modal {
    position: fixed;
    top: 5%;
    right: 5%;
    border-radius: 1rem;
    box-shadow: 0 0 30px rgba(0,0,0,0.7);
    background-color: white;
    min-height: 100px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid white;
  }

  .mobile .alert-modal {
    width: 94%;
    left: 3vw;
  }

  /* @media only screen and (max-width: 600px) {
    * {
      font-size: 0.88rem;
    }
  } */
</style>
