
/*
    Functions which are either useful in some generic way or are 
    used in multiple places within the code
*/


import CryptoJS from "crypto-js";

//
// transfer the querystring element within the current route
// into an object which can then be read and manipulated by
// the Vue components
//
export const setQueryParameters = (obj, clearMapValues) => {
    obj.query.layout = obj.$route.query.layout || null;
    obj.query.fontsize = obj.$route.query.fontsize || null;
    obj.query.scrolling = obj.$route.query.scrolling || null;
    obj.query.route = obj.$route.query.route || null;
    obj.query.direction = obj.$route.query.direction || null;
    obj.query.stop = obj.$route.query.stop || null;
    obj.query.station = obj.$route.query.station || null;
    obj.query.rows = obj.$route.query.rows || null;
    obj.query.tripid = obj.$route.query.tripid || null;
    obj.query.fromPlanner = obj.$route.query.fromPlanner || null;
    obj.query.lightrailType = obj.$route.query.lightrailType || null;
    obj.query.paramid = obj.$route.query.paramid || null;
    obj.query.qr = obj.$route.query.qr || null;
    obj.query.lineDisplay = obj.$route.query.linedisplay || null;
    obj.query.inStation = obj.$route.query.inStation || null;

    obj.query.from = obj.$route.query.from || null;
    obj.query.to = obj.$route.query.to || null;

    obj.query.fromNearby = obj.$route.query.fromNearby || null;

    if(!obj.query.tripid){
        obj.query.tripid = obj.$route.query.trainID || null;
    }

    obj.query.ip = obj.$route.query.ip || null;

    if(!obj.query.ip){
        obj.query.ip = obj.$route.query.IP || null;
    }

    obj.query.sign = obj.$route.query.sign || null;
    // obj.query.late = obj.$route.query.late || null;
    obj.query.status = obj.$route.query.status || null;

    if(obj.$route.query.status){
        obj.query.status = obj.$route.query.status.toUpperCase();
    }

    obj.query.dark = obj.$route.query.dark || null;
    if(obj.query.dark === "false"){
        obj.query.dark = false;
    }

    obj.query.footer = obj.$route.query.footer || null;
    if(obj.query.footer === "false"){
        obj.query.footer = false;
    }

    obj.query.hidemodeswitch = obj.$route.query.hidemodeswitch || null;
    if(obj.query.hidemodeswitch === "false"){
        obj.query.hidemodeswitch = false;
    }

    if(obj.query.layout === "s" && obj.query.scrolling === null){
        obj.query.scrolling = 30;
    }

    obj.query.late = Object.hasOwn(obj.$route.query, "latetrain") || obj.$route.query.late || null;
    if(obj.query.late === "false"){
        obj.query.late = false;
    }

    obj.query.shorten = obj.$route.query.shorten || null;
    if(obj.query.shorten === "false"){
        obj.query.shorten = false;
    }

    obj.query.messages = obj.$route.query.messages || null;
    if(obj.query.messages === "false"){
        obj.query.messages = false;
    }

    obj.query.filtered = obj.$route.query.filtered || null;
    if(obj.query.filtered === "false"){
        obj.query.filtered = false;
    }

    obj.query.fromMap = clearMapValues ? null : (obj.$route.query.fromMap || null);
    obj.query.fromHomeNearby = clearMapValues ? null : (obj.$route.query.fromHomeNearby || null);
    obj.query.stopName = clearMapValues ? null : (obj.$route.query.stopName || null);
    obj.query.vid = clearMapValues ? null : (obj.$route.query.vid || null);

    // I think this should be deprecated
    
    // obj.query.hidenav = obj.$route.query.hidenav || null;
    // if(obj.query.hidenav === "false"){
    //     obj.query.hidenav = false;
    // }
    // I think this should be deprecated
    // obj.query.showstation = obj.$route.query.showstation || null;
    // if(obj.query.showstation === "false"){
    //     obj.query.showstation = false;
    // }

    console.log("QUERY FINAL", obj.$route, obj.query, Object.hasOwn(obj.$route.query, "latetrain"));
};

//
// Create a copy of the input object,
// but do not include any fields which are null
//
// this is used to make sure that we don't pass along empty querystring values
//
export const onlyNonNullValues = (obj) => {
    const ret = {};

    if(obj){
        for(let key in obj){
            const val = obj[key];
            if(val || val === false){
                ret[key] = val;
            }
        }
    }

    return ret;
}


//
// return the default font-size based on the screen size options,
// which are defined within the main.js file
//
// const defaultMobileFontSize = "calc(2vmax + 0.5vmin)";
const defaultMobileFontSize = "calc(1.5vmax + 0.35vmin)";
const defaultLargeFontSize = "2vmin";

// will we use this one?
const defaultTabletFontSize = "calc(1.35vmax + 0.5vmin)"

// const defaultMobileFontSize = "2vh";
// const defaultLargeFontSize = "2vh";
export const determineDefaultFontSize = (query, mq, isSmall, source) => {

    console.log("QUERY FONTSIZE", query, query.fontsize, query.layout, mq, isSmall, source);
    console.log("QUERY FONTSIZE SMALL", mq, isSmall);

    const isTablet = false;

    if(query.fontsize){
        if(isNaN(query.fontsize)){
            return query.fontsize;
        }
        else{
            // assume pixels as default unit
            return query.fontsize + "px";
        }
    }
    else if(mq === 'mobile' || isSmall || query.layout === 'm'){
        return defaultMobileFontSize;
    }
    else if(isTablet){
        return defaultTabletFontSize;
    }
    else{
        return defaultLargeFontSize;
    }
}

//
// implement a simple debounce
//
// particularly useful for holding off on firing during map dragging and window resizing
//
export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            console.log("later", args);
            window.clearTimeout(timeout);
            func(...args);
        };

        window.clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
    };
}


//
// basic encryption/decryption of objects using the CryptoJS library
//
const _S_ = "792Hgt$3%6)ptttyp98";
export const encryptObject = (obj) => {
    // return CryptoJS.AES.encrypt(JSON.stringify(obj), _S_).toString();
    // return Encrypt(obj);
    const json = CryptoJS.AES.encrypt(JSON.stringify(obj), _S_).toString();
    const str = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(json));
    return str;
}

export const decryptObject = (str) => {
    // const bytes  = CryptoJS.AES.decrypt(str, _S_);
    // console.log("INVALID DECRYPT BYTES", str, bytes);
    // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // return Decrypt(str);

    const split = str.split("~*");

    return {
        internal_trip_number: split[0],
        sched_dep_time: split[1],
        timing_point_id: "",
        route: split[2],
        vehicle_id: split[3]
        // iconStyle: this.iconStyle
    };

    // const data = CryptoJS.enc.Base64.parse(str).toString(CryptoJS.enc.Utf8);
    // const jsonStr = CryptoJS.AES.decrypt(data, _S_).toString(CryptoJS.enc.Utf8);
    // console.log("DECRYPTED TRIP JSON STRING", jsonStr, str);
    // return JSON.parse(jsonStr);
}

export const encryptTrip = (trip) => {
    // const params = {
    //     internal_trip_number: trip.internal_trip_number,
    //     sched_dep_time: trip.sched_dep_time,
    //     timing_point_id: trip.timing_point_id,
    //     route: trip.route || trip.public_route || trip.linecode,
    //     vehicle_id: trip.vehicle_id
    //     // iconStyle: this.iconStyle
    // };
    // const params = {
    //     a: trip.VehicleInternalTripNumber,
    //     b: trip.VehicleScheduledDeparture,
    //     c: trip.VehicleRoute,
    //     d: trip.VehicleID
    //     // iconStyle: this.iconStyle
    // };

    return `${trip.VehicleInternalTripNumber}~*${trip.VehicleScheduledDeparture}~*${trip.VehicleRoute}~*${trip.VehicleID}`;

    // the only way to pas all of the information necessary for getting
    // the stops and maintaining proper future navigation to the 
    // "all trips" route is to have all of the above passed through
    // - to make this part of the URL, this object will be encrypted into a string
    //   and passed along in the querystring
    // return encryptObject(params);
}

//
// get a formatted time from a JS date object
// input can be either a date object or a date string
//
export const getTimeFromDatetime = (dt) => {
    const obj = new Date(dt);

    console.log("WHAT IS THE DATE OBJECT HERE?", obj, dt);

    if(!dt || isNaN(obj)){
        // display whatever was passed otherwise
        return dt;
    }

    // https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
    let ret = dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    ret = ret.split(" ")[1] + ret.split(" ")[2];

    const lastColon = ret.lastIndexOf(":");
    ret = ret.substring(0, lastColon) + " " + ret.substring(lastColon + 3);

    if(ret[0] === "0"){
        ret = ret.substring(1);
    }

    return ret.trim();
}

export const elementHasScrollbar = (element) => {
    if(element){
        return element.scrollHeight > element.clientHeight;
    }

    return false;
}

export const insertPlane = (str) => {
    const ret = str.replace("&#9992", "✈");
    console.log("INSERT PLANE?", str, ret);
    return ret;
}

// minimum of 20 feet in either lat or lng
export const minPositionDifference = (positionNew, positionOld) => {

    const latNew = positionNew.coords.latitude;
    const lonNew = positionNew.coords.longitude;

    const latOld = positionOld.lat;
    const lonOld = positionOld.lng;

    const latDiff = Math.abs(latNew - latOld);
    const lonDiff = Math.abs(lonNew - lonOld);

    return (latDiff > 0.00005 || lonDiff > 0.0006);
}

//
// remove the leading zero from a string
//
export const removeLead0 = (val) => {
    if(val && val.length > 0 && val[0] === "0"){
        return val.substring(1);
    }

    return val;
}