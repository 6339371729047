
/*

These are values which will be used across the requests:

 - the BaseURL for all API calls

 - method for transforming a javascript object into FormData for Post requests

*/

import axios from "axios";

// export const BaseURL = "http://10.20.21.40:8070";
export const BaseURL = "https://TESTPCSData.njtransit.com";
// export const BaseURL = "https://pcsdata.njtransit.com";

export const GenerateAudioURL = (ip) => `https://externalscroller.njtransit.com/scroller/ScrollMessage?ip=${ip}&format=json`;

export function createFormData(params, request) {
    const fd = new FormData();

    if(params){
        Object.keys(params).forEach((key) => {
            console.log("add key", request, key, params[key] || "");
            fd.append(key, (params[key] === false) ? false : params[key] || "");
        });
    }

    return fd;
}

let currentVueStore = null;

export function setCurrentStore(v) {
    console.log("SET THE CURRENT VUE OBJECT", v, v.$store);
    currentVueStore = v.$store;
}

export function getCurrentStore(){
    return currentVueStore;
}

const killSwitch = [];

export function appendToKillSwitch(func) {
    if(func && typeof(func) === "function" && !killSwitch.includes(func)){
        killSwitch.push(func);
    }
}

export function executeKillSwitch() {
    killSwitch.forEach(k => {
        k();
    });
}

export function runSim(token, time, prob, signal){
    const data = createFormData({
        timeToFinish: time,
        ProbOfSuccess: prob,
        token
    });

    const url = `https://testpcsauth.njtransit.com/api/Error/SimTest`;

    var config = {
        method: 'post',
        url,
        data
    };

    if(signal){
        // config.signal = signal;
    }

    return axios(config);
}