import { render, staticRenderFns } from "./NearbyStopDisplay.vue?vue&type=template&id=24052109&scoped=true&"
import script from "./NearbyStopDisplay.vue?vue&type=script&lang=js&"
export * from "./NearbyStopDisplay.vue?vue&type=script&lang=js&"
import style0 from "./NearbyStopDisplay.vue?vue&type=style&index=0&id=24052109&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24052109",
  null
  
)

export default component.exports