import { render, staticRenderFns } from "./App.vue?vue&type=template&id=23c51afc&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=23c51afc&prod&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&id=23c51afc&prod&scoped=true&lang=css&"
import style2 from "./App.vue?vue&type=style&index=2&id=23c51afc&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c51afc",
  null
  
)

export default component.exports