
NOT YET IMPLEMENTED

<template>
    <div :class="'nearby-routes' + ($isTabletForm ? ' mobile' : '') + ((routes && routes.length === 0) ? ' no-vehicles' : '') + ((pageType === 'rail') ? ' rail': '')">
        <!-- {{vehicleLocations ? "AAA" + vehicleLocations.length : "BBB" }} -->
        <div class="nearby-title">
            <div class="nearby-route-title">
                <div class="before-spacer"></div>
                <div class="route-element">
                    {{isHorizontal ? 'Nearby ' : ''}} 
                    <br v-if="isHorizontal" /> 
                    {{ pageType === "lightrail" ? "Nearby Buses" : "Routes" }}
                    <span v-if="$isTabletForm && routes && routes.length === 0 && loaded"> 
                        {{isReporting ? "no trains nearby right now" : "~ none nearby"}}
                    </span>
                    <!-- <span v-if="$isTabletForm && !loaded"> ~ loading...</span> -->
                </div>
            </div>
        </div>
        <div :class="'nearby-routes-container' + (updated ? ' updated' : '')">
            <NearbyRouteDisplay 
                v-bind:key="route.VehicleID" 
                v-for="(route, index) in loaded ? getFirst(routes) : []" 
                :routeName="route.VehicleRoute"
                :vehicleID="route.VehicleID"
                :index="index"
                @navigate-to-stops = "navigate"
            />
            <Loader v-if="$isTabletForm && !loaded" />
            <div v-if="!$isTabletForm && routes.length === 0 && loaded" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                {{isReporting ? "no trains nearby right now" : "none nearby"}}
            </div>
            
            <div style="position: absolute; left: 0px; background-color: rgba(255,255,255,0.5); padding: 1rem;" v-if="!$isTabletForm && !loaded && routes.length > 0" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                <Loader />
            </div>
            <div v-if="!$isTabletForm && !loaded && routes.length === 0" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                <Loader />
            </div>
        </div>
    </div>
</template>

vehicleLocations

instead of routes

<script>
    // import { mapGetters } from 'vuex';
    import { getVehicleLocations } from "@/requests/LocationRequests";
    import NearbyRouteDisplay from "./NearbyRouteDisplay";
    import Loader from "@/components/Loader";

    const OWN_REFRESH_TIME = 60000;

    let own_refresh_interval = [];
    let current_position_updating = false;

    let testdata = [];

    const setRailTestData = () => {
        testdata = [
            {VehicleRoute: "AC", VehicleID: 314},
            {VehicleRoute: "GS", VehicleID: 22},
            {VehicleRoute: "ME", VehicleID: 91},
            {VehicleRoute: "AC", VehicleID: 116},
            {VehicleRoute: "PR", VehicleID: 502},
            {VehicleRoute: "NE", VehicleID: 6}
        ];
    }

    const setBusLightTestData = () => {
        testdata = [
            {VehicleRoute: "101", VehicleID: 1},
            {VehicleRoute: "88", VehicleID: 2},
            {VehicleRoute: "102X", VehicleID: 3},
            {VehicleRoute: "1", VehicleID: 4},
            {VehicleRoute: "72", VehicleID: 5},
            {VehicleRoute: "34", VehicleID: 6}
        ];
    }

    export default {
        name: "NearbyRoutes",
        props: ["isHorizontal", "getOwnData", "currentPosition", "isReporting"],
        components: {
            NearbyRouteDisplay,
            Loader
        },
        data() {
            return {
                loaded: false,
                routes: [],
                pageType: "bus",
                updated: false
            }
        },
        watch: {
            currentPosition(newVal, oldVal) {
                console.log("CHANGE OWN POSITION NEARBY ROUTE", oldVal, newVal);
                if(this.$props.getOwnData && !current_position_updating){
                    this.clearAllIntervals();
                    current_position_updating = true;
                    setTimeout(this.getRouteData, 50);
                }
            }
        },
        computed: {
            // ...mapGetters({
            //     vehicleLocations: 'vehicleLocations'
            // })
            vehicleLocations() {
                console.log("VEHICLE STORE RETRIEVAL", this.$store.getters.vehicleLocations);
                return this.$store.getters.vehicleLocations;
            }
        },
        created() {
            this.unsubscribe = this.$store.subscribe((mutation, state) => {
                console.log("SUBSCRIBED, WATCHING", mutation);
                if (mutation.type === 'setVehicleLocations') {
                    console.log(`Updating to vehiclelocations ${state.stopLocations}`);

                    this.loaded = true;
                    this.routes = state.vehicleLocations;
                }
                else if(mutation.type === "setVehiclesLoaded"){
                    console.log("");
                    this.loaded = state.vehiclesLoaded;
                }
            });
        },
        beforeDestroy(){
            this.unsubscribe();
        },
        mounted() {
            console.log("NEARBY MEMBER", this.vehicleLocations);
            
            console.log("ROUTES CHECK FOR MEMBER NAMES", this.routes);

            this.pageType = this.$route.path.split("/")[1];

            console.log("NEARBY ROUTES PAGE TYPE", this.pageType);

            if(this.pageType === "rail"){
                setRailTestData();
            }
            else {
                setBusLightTestData();
            }

            this.routes = testdata;
            this.routes = [];

            if(this.$props.getOwnData){
                this.clearAllIntervals();
                setTimeout(this.getRouteData, 500);
            }
            else{
                const r = this.vehicleLocations;
                console.log("VUEX ROUTES", r);
                this.routes = this.getFirst(r);
                // this.routes = [];
            }
        },
        destroyed() {
            this.clearAllIntervals();
        },
        methods: {
            navigate(index) {
                console.log("MEMBER NAVIGATE", index, this.routes, this.routes[index], this.$props.isReporting);

                // if(this.$props.isReporting){
                //     console.log("THIS IS REPORTING", index, this.routes[index]);
                //     this.$emit("reportingCall", this.routes[index]);
                //     return;
                // }

                this.$emit("navigate-to-stops", this.routes[index], true);
            },
            getFirst(list){
                const results = [];

                const limit = this.$props.isHorizontal ? 6 : 3;

                // need to remove the copies first
                const copies = {};
                let count = 0;
                let index = 0;

                console.log("NEARBY DATA", list);

                while (count < limit && index < list.length){
                    const vehicle = list[index];
                    const key = vehicle.VehicleID + "__" + vehicle.VehicleInternalTripNumber + "__" + vehicle.VehicleRoute + "__" + vehicle.Direction;

                    console.log("NEARBY DATA CHECK", key, copies);

                    if(copies[key]){
                        // just move on
                    }
                    else {
                        copies[key] = true;
                        results.push(vehicle);
                        count++;
                    }

                    index++;
                }

                console.log("NEARBY DATA RESULTS", results);

                return results;

                // return list.length > limit ? list.slice(0, limit) : list;
            },
            clearAllIntervals() {
                own_refresh_interval.forEach(i => {
                    clearInterval(i);
                });

                own_refresh_interval = [];
            },
            getRouteData() {
                console.log("GET OWN ROUTE DATA YOURSELF");

                // const lat = 40.7357
                // const lng = -74.1974;

                if(!this.$props.currentPosition) {
                    return;
                }

                console.log("OWN DATA TO RUN ROUTE");

                const {lat, lng} = this.$props.currentPosition;

                const distance = this.$props.getOwnData ? 10000 : 10000;
                const params = {lat, lng, radius: distance};

                const pageType = this.$route.path.split("/")[1];

                switch(pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "hblr":
                        params.mode = "RL";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        break;
                }

                console.log("MY OWN PARAMETERS ROUTES", params);

                getVehicleLocations(params)
                .then(({data}) => {
                    console.log("OWN ROUTES DATA", data);
                    this.routes = this.getFirst(data);
                    const newData = this.getFirst(data);
                    this.checkForUpdate(newData);
                    this.loaded = true;
                    this.routes = newData;

                    // this.routes = this.getFirst(testdata);
                    // console.log("USE THIS TEST DATA?", testdata, this.getFirst(testdata));

                    current_position_updating = false;
                })
                .catch(err => {
                    if((err.response && err.response.status === 408) || err.code === 'ECONNABORTED'){
                        console.log("ROUTES TIMEOUT");
                    }
                    this.loaded = true;
                    current_position_updating = false;
                });

                // shift back to await next change
                setTimeout(() => {this.updated = false}, 2000);

                // update every 5 minutes
                own_refresh_interval.push(setTimeout(this.getRouteData, OWN_REFRESH_TIME));
            },
            checkForUpdate(newData) {
                // if the data values are different, then update

                if(newData.length !== this.routes.length){
                    this.updated = true;
                }
                else{
                    this.routes.forEach((r, i) => {
                        if(r.VehicleRoute !== newData[i].VehicleRoute){
                            this.updated = true;
                            return;
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .nearby-routes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* max-width: 500px; */
        width: 100%;
        margin-bottom: calc(4 * var(--mvh));
        /* padding-right: 1vw; */
    }

    .nearby-routes{
        width: 100%;
        flex-direction: column;
        /* display: grid;
        grid-template-columns: 1fr 8fr; */
        gap: 1rem;
        margin-bottom: calc(4 * var(--mvh));
        /* padding-left: 1rem; */
        align-items: flex-start;
    }
    
    .mobile .nearby-routes {
        align-items: center;
    }

    .horizontal .nearby-routes {
        flex-direction: row;
        width: 100%;
        gap: 4rem;
        margin-bottom: calc(4 * var(--mvh));
        padding-left: 1rem;
        align-items: center;
    }

    .no-vehicles {
        font-size: 1.5rem;
        /* flex-direction: row; */
    }

    .horizontal .no-vehicles {
        /* display: grid;
        grid-template-columns: 1fr 1fr; */
    }

    .nearby-routes.no-vehicles * {
        font-size: 1.5rem;
    }

    .no-vehicles .nearby-routes-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .none-message {
        /* margin-top: -4.05rem; */
    }

    .horizontal-none-message {
        margin-top: initial;
    }

    .mobile .none-message {
        padding-left: 4.5rem;
        margin-top: -3.75rem;
    }

    .nearby-routes-container {
        position: relative;
        width: 100%;
        /* display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem; */
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .horizontal .nearby-routes-container {
        display: flex;
        gap: 10vw;
        justify-content: flex-start;
    }

    .report-modal .nearby-routes-container {
        transform: scale(1.15);
    }

    .horizontal .rail .nearby-routes-container {
        gap: 9vw;
    }

    .nearby-title {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
    }

    .before-spacer {
        width: 1.5vw;
    }

    .mobile .before-spacer {
        width: 0;
    }

    .nearby-route-title {
        display: flex;
        position: relative;
        z-index: 1;
    }

    .route-element {
        position: relative;
        font-size: 1.5rem;
    }

    .report-modal .route-element {
        display: none;
    }

    .floating {
        position: absolute;
        /* background-color: rgba(255,255,255, 0.8); */
        background-color: red;
        padding: 10px;
        top: 50px;
        left: -50vw;
        z-index: 2;
    }

    .mobile .route-element {
        background: white;
        padding: 0 1rem;
    }

    .mobile .report-mode .route-element {
        background-color: #fde1e1;
    }

    .route-element::before {
        content: "";
        position: absolute;
        top: 0;
        left: -0.8rem;
        height: 100%;
        width: 0.3rem;
        background-color: var(--primary-color);
    }

    .mobile .route-element::before {
        content: "";
        position: absolute;
        top: 50%;
        /* left: -1rem;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%; */
        left: -70vw;
        height: 1px;
        width: 150vw;
        z-index: -1;
        transform: translateY(-50%);
        background-color: var(--primary-color);
        opacity: 0.5;
    }

    .route-number {
        background-color: var(--primary-color);
        border-radius: 50%;
        color: white;
        width: 7vw;
        height: 7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 2px -2px rgb(0 0 0 / 25%), 
            0px 3px 3px 0px rgb(0 0 0 / 19%), 
            0px 2px 5px 0px rgb(0 0 0 / 16%);
    }

    @media only screen and (min-width: 601px) and (max-width: 1050px) {
        .nearby-routes {
            width: 500px;
            margin-bottom: calc(10 * var(--mvh));
        }
    }

    .mobile .route-number {
        background-color: navy;
        border-radius: 50%;
        color: white;
        width: 12vw;
        height: 12vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .updated {
        animation: highlight 1s ease-in-out;
    }

    @keyframes highlight {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    /* @media only screen and (max-width: 600px) {
        .route-number {
            background-color: navy;
            border-radius: 50%;
            color: white;
            width: 12vw;
            height: 12vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    } */
</style>