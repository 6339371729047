
NOT YET IMPLEMENTED

<template>
    <div :class="'nearby-stops' + ($isTabletForm ? ' mobile' : '') + ((stops && stops.length === 0) ? ' no-stops' : '')">
        <div :class="'nearby-title' + (!isHorizontal ? ' home-title' : '')">
            <div class="nearby-stops-title">
                <div class="before-spacer"></div>
                <div class="stop-element">
                    {{isHorizontal ? 'Nearby ' : ''}} 
                    <br v-if="isHorizontal" /> 
                    {{(pageType === "rail") ? "Stations" : "Stops"}} 
                    <span v-if="$isTabletForm && stops && stops.length === 0 && loaded"> 
                        {{isReporting ? "no trains nearby right now" : "~ none nearby"}}
                    </span>
                    <!-- <span v-if="$isTabletForm && !loaded"> ~ loading...</span> -->
                </div>
            </div>
        </div>
        <div v-if="$isTabletForm && stops.length !== 0" class="mobile-stops-container">
            <NearbyStopDisplay 
                v-bind:key="stop.VehicleID" 
                v-for="(stop, index) in loaded ? getFirstLocations(stops) : []" 
                :stopName="stop.busstopdescription"
                :stopID="stop.busstopnumber"
                :mode="stop.modetype"
                :index="index"
                @navigate-to-trips = "navigate"
            />
        </div>
        <div v-else class="landscape-stops-container">
            <NearbyStopDisplay
                v-bind:key="stop.VehicleID" 
                v-for="(stop, index) in getFirstLocations(stops)" 
                :stopName="stop.busstopdescription"
                :stopID="stop.busstopnumber"
                :mode="stop.modetype"
                :index="index"
                :isHorizontal="isHorizontal"
                @navigate-to-trips = "navigate"
            />
            <Loader v-if="$isTabletForm && !loaded && stops.length === 0" />
            <div v-if="!$isTabletForm && stops.length === 0 && loaded" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                {{isReporting ? "no trains nearby right now" : "none nearby"}}
            </div>

            <div style="position: absolute; top: 0px; left: 0px; background-color: rgba(255,255,255,0.5); padding: 1rem;" v-if="!$isTabletForm && !loaded && routes.length > 0" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                <Loader />
            </div>
            <div v-if="!$isTabletForm && !loaded && routes.length === 0" :class="isHorizontal ? 'horizontal-none-message' : 'none-message'">
                <Loader />
            </div>
        </div>
    </div>
</template>


stopLocations

                :stopName="stop.busstopdescription"
                :stopID="stop.busstopnumber"


<script>
    // import { mapGetters } from 'vuex';
    import { getStopAndTerminalLocations } from "@/requests/LocationRequests";
    import NearbyStopDisplay from "./NearbyStopDisplay";
    import Loader from "@/components/Loader";

    const OWN_REFRESH_TIME = 60000;

    let own_refresh_interval = [];
    let current_position_updating = false;

	const testdata = [
        
		{"VehicleDestination":"Long Branch","VehicleDistanceMiles":8732,"VehicleRoute":"NC","VehicleID":"3281","VehicleLat":"40.713472","VehicleLong":"-74.185678","VehiclePassengerLoad":""},
		{"VehicleDestination":"New York","VehicleDistanceMiles":9272,"VehicleRoute":"NE","VehicleID":"3872","VehicleLat":"40.734278","VehicleLong":"-74.16391","VehiclePassengerLoad":""},
		{"VehicleDestination":"A Long Stop Name Right Here Too","VehicleDistanceMiles":16883,"VehicleRoute":"ME","VehicleID":"0336","VehicleLat":"40.761574","VehicleLong":"-74.248053","VehiclePassengerLoad":""},
		{"VehicleDestination":"Lk Hopatcong","VehicleDistanceMiles":21475,"VehicleRoute":"MC","VehicleID":"1011","VehicleLat":"40.794497","VehicleLong":"-74.201265","VehiclePassengerLoad":""},
		{"VehicleDestination":"New York","VehicleDistanceMiles":27696,"VehicleRoute":"NC","VehicleID":"3514","VehicleLat":"40.752024","VehicleLong":"-74.099535","VehiclePassengerLoad":""}
        
    ];

    export default {
        name: "NearbyStops",
        props: ["isHorizontal", "getOwnData", "currentPosition", "isReporting"],
        components: {
            NearbyStopDisplay,
            Loader
        },
        data() {
            return {
                loaded: true,
                stops: [
                /*
                    {
                    busstopdescription: "22ND STREET LIGHT RAIL STATION",
                    busstopnumber: "30845",
                    latitude: "40.66",
                    longitude: "-74.11",
                    distance: "222.63",
                    modetype: "HBLR"
                    }
                */
                ],
                pageType: ""
            }
        },
        watch: {
            currentPosition(newVal, oldVal) {
                console.log("CHANGE OWN POSITION NEARBY STOP", oldVal, newVal);
                if(this.$props.getOwnData && !current_position_updating) {
                    this.clearAllIntervals();
                    setTimeout(this.getStopData, 50);
                    current_position_updating = true;
                }
            }
        },
        computed: {
            // ...mapGetters({
            //     stopLocations: 'stopLocations'
            // })
            stopLocations() {
                return this.$store.getters.stopLocations;
            },
        },
        created() {
            this.pageType = this.$route.path.split("/")[1];

            this.unsubscribe = this.$store.subscribe((mutation, state) => {
                console.log("SUBSCRIBED, WATCHING", mutation);
                if (mutation.type === 'setStopLocations') {
                    console.log(`Updating to stoplocations ${state.stopLocations}`);

                    this.loaded = true;
                    this.stops = state.stopLocations;
                }
                else if(mutation.type === "setStopsLoaded"){
                    console.log("STOPS LOADED WATCH", state.stopsLoaded);
                    this.loaded = state.stopsLoaded;
                }
            });
        },
        beforeDestroy(){
            this.unsubscribe();
        },
        mounted() {
            // return testdata;
            this.stops = testdata;
            this.stops = [];

            console.log("DO WE GET OWN STOPS?", this.$props.getOwnData);

            if(this.$props.getOwnData) {
                this.clearAllIntervals();
                setTimeout(this.getStopData, 300);
            }
            else{
                const r = this.stopLocations;
                console.log("VUEX STOP LOCATIONS", r);
                this.stops = this.getFirstLocations(r);
            }
        },
        destroyed(){
            this.clearAllIntervals();
        },
        methods: {
            navigate(index) {
                console.log("MEMBER NAVIGATE", index, this.stopLocations, this.stopLocations[index]);
                const stop = this.stops[index];

                console.log("NAVIGATE TO TRIPS FROM NEARBY STOPS", stop, this.$props.isReporting);

                // if(this.$props.isReporting){
                //     console.log("THIS IS REPORTING", stop);
                //     this.$emit("reportingCall", stop);
                //     return;
                // }

                this.$emit("navigate-to-trips", stop.busstopnumber, null, null, stop.busstopdescription, stop.modetype);
            },
            getFirstLocations(list){
                const limit = this.$isTabletForm ? 3 : 3;
                return list.length > limit ? list.slice(0, limit) : list;
            },
            clearAllIntervals() {
                own_refresh_interval.forEach(i => {
                    clearInterval(i);
                });

                own_refresh_interval = [];
            },
            getStopData() {
                console.log("GET OWN STOPS DATA YOURSELF", this.$props.currentPosition);

                // const lat = 40.7357
                // const lng = -74.1974;

                if(!this.$props.currentPosition) {
                    return;
                }

                console.log("OWN DATA TO RUN");

                const {lat, lng} = this.$props.currentPosition;

                const distance = this.$props.getOwnData ? 10000 : 10000;
                const params = {lat, lng, radius: distance};

                const pageType = this.$route.path.split("/")[1];

                switch(pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "hblr":
                        params.mode = "HBLR";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        break;
                }

                console.log("MY OWN PARAMETERS STOPS", params);


                getStopAndTerminalLocations(params)
                .then(({data}) => {
                    console.log("OWN STOPS DATA", data);
                    this.loaded = true;
                    this.stops = this.getFirstLocations(data);

                    current_position_updating = false;
                })
                .catch(err => {
                    if((err.response && err.response.status === 408) || err.code === 'ECONNABORTED'){
                        console.log("STOPS TIMEOUT");
                    }
                    this.loaded = true;
                    console.log("timeout loaded", this.loaded);

                    current_position_updating = false;
                });

                // update every 5 minutes
                own_refresh_interval.push(setTimeout(this.getStopData, OWN_REFRESH_TIME));
            }
        }
    }
</script>

<style scoped>
    .nearby-stops {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* max-width: 500px; */
        width: 100%;
        margin-bottom: 10px;
    }

    .nearby-stops {
        width: 100%;
        flex-direction: column;
        /* display: grid;
        grid-template-columns: 1fr 8fr; */
        gap: 1rem;
        margin-bottom: calc(4 * var(--mvh));
        /* padding-left: 1rem; */
    }

    .nearby-title {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        margin-right: 1vw;
    }

    .horizontal .nearby-title {
        margin-right: 0;
    }

    .home-title {
        align-self: flex-start;
        margin-top: 18px;
        transform: translateY(-50%);
    }

    .mobile .home-title {
        align-self: center;
    }

    .nearby-stops.no-stops {
        /* display: grid;
        grid-template-columns: 1fr 1fr; */
    }

    .nearby-stops.no-stops * {
        font-size: 1.5rem;
    }

    .none-message {
        /* margin-top: -4.05rem; */
    }

    .horizontal-none-message {
        margin-top: initial;
        justify-self: flex-start;
        font-size: 1.5rem;
    }

    .mobile .none-message {
        padding-left: 4.5rem;
        margin-top: -3.75rem;
    }

    .horizontal .nearby-stops {
        flex-direction: row;
        margin-bottom: 0;
        gap: 4rem;
        padding-left: 1rem;
    }

    .before-spacer {
        width: 1.5vw;
    }

    .mobile .before-spacer {
        width: 0;
    }

    .nearby-stops-title {
        display: flex;
        position: relative;
        z-index: 1;
    }

    .stop-element {
        position: relative;
        font-size: 1.5rem;
    }

    .report-modal .stop-element {
        display: none;
    }

    .mobile .stop-element {
        background: white;
        padding: 0 1rem;
    }

    .mobile .report-mode .stop-element {
        background-color: #fde1e1;
    }

    .stop-element::before {
        content: "";
        position: absolute;
        top: 0;
        left: -0.8rem;
        height: 100%;
        width: 0.3rem;
        background-color: var(--primary-color);
    }

    .mobile .stop-element::before {
        content: "";
        position: absolute;
        top: 50%;
        /* left: -1rem;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%; */
        left: -70vw;
        width: 150vw;
        height: 1px;
        z-index: -10;
        opacity: 0.5;
        transform: translateY(-50%);
        background-color: var(--primary-color);
    }

    .stop {
        padding: 0.5vw;
        border: 0.1vw solid black;
        border-radius: 1vw;
        border-top: 1px solid var(--primary-color);
        box-shadow: 0px 4px 2px -2px rgb(0 0 0 / 25%), 
            0px 3px 3px 0px rgb(0 0 0 / 19%), 
            0px 2px 5px 0px rgb(0 0 0 / 16%);
    }

    .mobile-stops-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: calc(2 * var(--mvh));
    }

    .mobile-stops-container .v-btn {
        width: 75%;
        height: initial;
        min-width: 50vw;
        /* justify-content: flex-start; */
    }

    .landscape-stops-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* padding: 0 calc(6vw + 7px) 0 0; */
    }

    .horizontal .landscape-stops-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        align-items: flex-start;
        padding: 0 5vw 0 0;
    }

    .horizontal .v-btn {
        min-width: initial !important;
    }

    @media only screen and (min-width: 601px) and (max-width: 1050px) {
        .nearby-stops {
            width: 500px;
        }
    }

    .mobile .stop {
        padding: 1vw;
        border: 0.1vw solid black;
        border-radius: 1vw;
    }

    /* @media only screen and (max-width: 600px) {
        .stop {
            padding: 1vw;
            border: 0.1vw solid black;
            border-radius: 1vw;
        }
    } */

</style>